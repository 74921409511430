/* eslint-disable */
import store from "@/store";
import router from "@/router";
import { defer, toMarketingCluster, isEmpty } from "@/utils";

import { handleConnectorApp } from "@/services/connector";

/**
 * ConnectorApp
 * @description Handles connector app configuration
 * @author {Jatin Kamboj}
 */
class ConnectorApp {
  constructor() {
    this.computeConfig();
  }

  /**
   * computeConfig
   * Fetch config from route query params or from the store
   */
  async computeConfig() {
    this.config =
      this.getRouteConfig() ?? store.getters["ui/connectorAppParams"] ?? {};
  }

  /**
   * Get connector config from query params
   */
  getRouteConfig() {
    return router?.app?.$route?.query ?? "";
  }

  setConnectorAppParams(data) {
    store.dispatch("ui/setConnectorAppParams", data);
  }

  /**
   * @private Remove config once it have been used
   */
  #removeConfig() {
    if (!isEmpty(store.getters["ui/connectorAppParams"])) {
      this.setConnectorAppParams(null);
    }
    // if (this.getRouteConfig()) router.replace({ query: null });
  }

  /**
   * Computes redirect url of marketing app
   */
  // computeRedirectUrl(redirectUrl) {
  //   try {
  //     let url = toMarketingCluster(store.getters["auth/cookieToken"], false);
  //     url = new URL(url);
  //     url.searchParams.append("redirect_url", encodeURIComponent(redirectUrl));

  //     return url.toString();
  //   } catch {
  //     return "";
  //   }
  // }

  /**
   * handleRequest
   * @description handles connector store connectivity request
   */
  async handleRequest(payload) {
    try {
      await this.computeConfig();
      if(payload.shopifyState){
        payload.state = payload.shopifyState;
        delete payload.shopifyState;
      }

      const { data } = await handleConnectorApp(payload);
      await store.dispatch("ui/setConnectorAppParams", null);

     // const redirectUrl = this.computeRedirectUrl(data.redirect_url);
      defer(() => (window.location.href = data.redirect_url), 10);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      this.#removeConfig();
    }
  }
}

export default ConnectorApp;
